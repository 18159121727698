import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek2 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/A2-2.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/A2-2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">OUR APARTMENTS</h1>
          <div className="content-container">

            <h1>Amber Apartament 2</h1>

            <p>This 30 sq m apartament is located on the first floor.</p>
            <p>A living room is equipped with a sofa with a sleeping function. The flat consists also of a fully equipped kitchen, a bedroom with a king-size bed, a capatious wardrobe and a TV set, a hall with another wardrobe, a bathroom, and a balcony. The balcony overlooks the courtyard. There’s a free private parking spot in the underground garage at your disposal.</p>

            <h1>Equipment:</h1>
            <ul>
              <li><FaCheck />air conditioning</li>
              <li><FaCheck />a smart TV set, 49 inch (in the living room)</li>
              <li><FaCheck />wi-fi</li>
              <li><FaCheck />an induction hob</li>
              <li><FaCheck />a dishwasher</li>
              <li><FaCheck />an electric filter coffee maker</li>
              <li><FaCheck />a kettle</li>
              <li><FaCheck />a toaster</li>
              <li><FaCheck />an integrated fridge / freezer</li>
              <li><FaCheck />a microwave oven</li>
              <li><FaCheck />glassware, cutlery and cooking utensils</li>
              <li><FaCheck />a table with 4 chairs</li>
              <li><FaCheck />a coffee table</li>
              <li><FaCheck />a smart TV set, 32 inch in the bedroom</li>
              <li><FaCheck />a washing machine</li>
              <li><FaCheck />a shower, a washbasin with a countertop, a toilet</li>
              <li><FaCheck />a few sets of towels</li>
              <li><FaCheck />a vacuum cleaner</li>
              <li><FaCheck />a sofa with a sleeping function</li>
              <li><FaCheck />a king-size bed</li>
              <li><FaCheck />a big wardrobe with shelves and clothes hangers</li>
              <li><FaCheck />a hair dryer</li>
              <li><FaCheck />an iron and ironining board</li>
              <li><FaCheck />a windbreaker, a beach tent and a set of toys for kids</li>
            </ul>

            <p style={{ marginTop: 20 }}>In this apartament pets <b>ARE allowed</b>.</p>

          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Photo Gallery</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament2' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek2

